import React from 'react';
import styles from './BlogGrid.module.scss';
import DisplayBlogPosts from './DisplayBlogPosts';

const CreateGrid = ({ blogPosts, sliderWidth, sliderHeight, articleWidth, articleHeight }) => {
  const container = [];
  for (let i = 0; i < blogPosts.length; i += 12) {
    const cardSlice = blogPosts.slice(i, i + 12);
    const cardType = cardSlice.reduce(
      (byType, item) => {
        // Initialize the entry to an empty array if it does not already exist.
        byType[item.type] = byType[item.type] || [];

        // Add the item to the collection of all items of that type.
        byType[item.type].push(item);

        return byType;
      },
      {
        article: [],
        audio: [],
        quote: [],
        slider: [],
      }
    );

    const articles = cardType.article ? cardType.article : [];
    const sliders = cardType.slider ? cardType.slider : [];
    const quotes = cardType.quote ? cardType.quote : [];
    const audio = cardType.audio ? cardType.audio : [];

    // remove audio cards for now as they are not supported
    delete cardType.audio;

    let lastSliderSlides;

    const articleAmount = articles.length;
    const slidersAmount = sliders.length;
    const quotesAmount = quotes.length;
    const audioAmount = audio.length;

    // if these type of card exsist make sure they are limited at 2
    if (slidersAmount > 2) {
      sliders.splice(0, 2);
    }
    if (quotesAmount > 2) {
      quotes.splice(0, 2);
    }
    if (audioAmount > 2) {
      audio.splice(0, 2);
    }

    //if the total of audio and quite > 2 then trim them evenly
    if (audioAmount + quotesAmount > 2) {
      quotes.splice(0, 1);
      audio.splice(0, 1);
    }

    const quoteOrAudio = quotesAmount + audioAmount;
    const postAmount = articleAmount + slidersAmount;

    if (sliders && sliders.length > 0) {
      lastSliderSlides = sliders[sliders.length - 1].slides;
    }

    if (postAmount >= 4) {
      if (postAmount > 4 && postAmount < 7) {
        if (slidersAmount > 0) {
          lastSliderSlides.push(...articles.splice(2));
        } else {
          sliders.push({ slides: articles.splice(2), type: 'slider' });
        }
      }

      if (postAmount > 7 && postAmount < 10) {
        if (slidersAmount > 0) {
          lastSliderSlides.push(...articles.splice(6));
        } else {
          sliders.push({ slides: articles.splice(6), type: 'slider' });
        }
      }

      if (postAmount > 10 && quoteOrAudio === 0) {
        if (slidersAmount > 0) {
          lastSliderSlides.push(...articles.splice(slidersAmount === 1 ? 9 : 9 - (slidersAmount - 1)));
        } else {
          sliders.push({ slides: articles.splice(9), type: 'slider' });
        }
      }

      if (quoteOrAudio === 0) {
        container.push(
          <div className={styles[`container${postAmount > 10 ? 10 : postAmount}`]} key={Math.random()}>
            {
              <DisplayBlogPosts
                cardTypes={[cardType.article, cardType.slider]}
                articleWidth={articleWidth}
                articleHeight={articleHeight}
                sliderWidth={sliderWidth}
                sliderHeight={sliderHeight}
              />
            }
          </div>
        );
      }

      if (quoteOrAudio > 0) {
        container.push(
          <div className={styles[`container${postAmount}With${quoteOrAudio}Special`]} key={Math.random()}>
            {
              <DisplayBlogPosts
                cardTypes={[cardType.article, cardType.slider, cardType.quote, cardType.audio]}
                articleWidth={articleWidth}
                articleHeight={articleHeight}
                sliderWidth={sliderWidth}
                sliderHeight={sliderHeight}
              />
            }
          </div>
        );
      }
    }
  }

  return container;
};

export default CreateGrid;
