/* eslint-disable react/prop-types */
import loadable from '@loadable/component';
import classnames from 'classnames';
import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';

import { ReactComponent as BookmarkIcon } from '../../images/icons/bookmarks.svg';
import { useUserProfile } from '../../utils/useUserProfile';

import styles from './BookmarkBlogButton.module.scss';

const LoginModal = loadable(() => import(/* webpackChunkName: 'LoginModal' */ '../LoginModal/LoginModal'));

const BookmarkBlogButton = ({ blogId, classList = {} }) => {
  const profile = useSelector(({ profile }) => profile);
  const { deleteBlogBookmark, addBlogBookmark } = useUserProfile();
  const [openModal, setOpenModal] = useState(false);
  const isBookmarked = profile && profile.bookmarkedBlogs.includes(blogId);

  const handleBookmarkBtnPress = (e) => {
    e.preventDefault();

    if (!profile) {
      setOpenModal(true);
      return;
    }

    if (isBookmarked) {
      //todo: pass only token and slug to api interface
      deleteBlogBookmark({
        body: JSON.stringify({ slug: blogId }),
        method: 'DELETE',
      });
      return;
    }

    //todo: pass only token and slug to api interface
    addBlogBookmark({
      body: JSON.stringify({ slug: blogId }),
      method: 'POST',
    });
  };

  return (
    <>
      <FormattedMessage default="Bookmark" id="profile.bookmark">
        {(label) => (
          <button
            aria-label={label}
            className={classnames(
              styles.root,
              classList.root,
              classList.bookmarked && { [classList.bookmarked]: isBookmarked }
            )}
            onClick={handleBookmarkBtnPress}
          >
            <BookmarkIcon />
          </button>
        )}
      </FormattedMessage>
      {openModal && <LoginModal isOpen={openModal} setOpen={setOpenModal} />}
    </>
  );
};

export default BookmarkBlogButton;
