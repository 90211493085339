/* eslint-disable react/prop-types */
import React from 'react';
import loadable from '@loadable/component';
import styles from './BlogGrid.module.scss';
import { Link } from 'react-router-dom';
import BookmarkBlogButton from '../BookmarkBlogButton/BookmarkBlogButton';

const Sliders = loadable(() => import('../Sliders/Sliders'));
const getImageWidthForSliderCard = () => '758';

const SliderCard = ({ card, sliderWidth, sliderHeight }) => {
  return (
    <Sliders
      classList={{ card: styles.sliderCard, root: styles.parent, color: styles.navBackground }}
      gap={0}
      viewAll={false}
      pagination={true}
    >
      {card.slides.map((slide, index) => {
        return (
          <React.Fragment key={index}>
            <Link to={slide.slug} className={styles.sliderCardLink}>
              <div className={styles.slide}>
                <div className={styles.sliderImageContainer}>
                  <img
                    alt={slide.title}
                    loading="lazy"
                    src={`https://res.cloudinary.com/crop/image/fetch/f_auto,q_70,fl_lossy,c_scale,w_${getImageWidthForSliderCard()}/${slide.featured_image ||
                      slide.image}`}
                    width={sliderWidth}
                    height={sliderHeight}
                    title={slide.title}
                  />
                </div>
                <div className={styles.slideContent}>
                  <div className="h3">{slide.title}</div>
                </div>
                <div className={styles.bottom}>
                  <BookmarkBlogButton blogId={slide.slug} classList={{ root: styles.like, bookmarked: styles.liked }} />
                </div>
              </div>
            </Link>
          </React.Fragment>
        );
      })}
    </Sliders>
  );
};

export default SliderCard;
