import React from 'react';
import ArticleCard from './ArticleCard';
import AudioCard from './AudioCard';
import QuoteCard from './QuoteCard';
import SliderCard from './SliderCard';
import styles from './BlogGrid.module.scss';

const DisplayBlogPosts = ({ cardTypes, sliderHeight, sliderWidth, articleWidth, articleHeight }) => {
  let cardNumber = 0;

  return cardTypes
    .filter((types) => !!types)
    .map((cardType) => {
      return cardType.map((item, index) => {
        cardNumber += 1;

        if (item.type === 'slider') {
          return (
            <div className={styles[`containerItem${cardNumber}`]} key={item.title + index}>
              <SliderCard card={item} sliderHeight={sliderHeight} sliderWidth={sliderWidth} />
            </div>
          );
        }

        if (item.type === 'audio') {
          return (
            <div className={styles[`containerItem${cardNumber}`]} key={item.title + index}>
              <AudioCard card={item} />
            </div>
          );
        }

        if (item.type === 'quote') {
          return (
            <div className={styles[`containerItem${cardNumber}`]} key={item.title + index}>
              <QuoteCard card={item} />
            </div>
          );
        }

        if (item.type === 'article') {
          return (
            <div className={styles[`containerItem${cardNumber}`]} key={item.title + index}>
              <ArticleCard card={item} articleWidth={articleWidth} articleHeight={articleHeight} />
            </div>
          );
        }

        return (
          <div className={styles[`containerItem${cardNumber}`]} key={item.title + index}>
            {item.type}
          </div>
        );
      });
    });
};

export default DisplayBlogPosts;
