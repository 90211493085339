/* eslint-disable react/prop-types */
import React from 'react';
import styles from './BlogGrid.module.scss';

const QuoteCard = ({ card }) => {
  return (
    <article className={styles.quote} tabIndex={0}>
      <div className="h3">{card.title}</div>
    </article>
  );
};

export default QuoteCard;
