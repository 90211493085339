/* eslint-disable react/prop-types */
import React from 'react';
import loadable from '@loadable/component';
import parse from 'html-react-parser';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import styles from './BlogGrid.module.scss';
import { ReactComponent as Clock } from '../../images/icons/time.svg';

const BookmarkBlogButton = loadable(() => import('../BookmarkBlogButton/BookmarkBlogButton'));

const getFeaturedImageWidthForArticleCard = () => '371';

const ArticleCard = ({ card, articleWidth, articleHeight }) => {
  const { bubble, article_category, featured_image, reading_time, introduction, slug, size, title } = card;

  let allowContent = true;
  let numberOfParagraphsFound = 0;
  const postContentAfterStrippingContent =
    introduction &&
    parse(introduction, {
      // eslint-disable-next-line react/display-name
      replace: (domNode) => {
        if (domNode.name === 'img' || domNode.name === 'a') {
          return <span />;
        }

        if (allowContent) {
          if (domNode.name === 'p') {
            numberOfParagraphsFound += 1;
            if (numberOfParagraphsFound === 2) {
              allowContent = false;
            }
          }

          return domNode;
        }

        return <></>;
      },
    });

  return (
    <Link to={slug} className={styles.articleLink}>
      <article className={classNames(styles.article, { [styles.small]: size === 's' })} tabIndex="0">
        {bubble !== '' && <span className={styles.bubble}>{bubble}</span>}
        {featured_image !== '' && (
          <div className={styles.imageContainer}>
            <img
              alt={title}
              loading="lazy"
              src={`https://res.cloudinary.com/crop/image/fetch/f_auto,q_70,c_scale,fl_lossy,w_${getFeaturedImageWidthForArticleCard()}/${featured_image}`}
              title={title}
              width={articleWidth}
              height={articleHeight}
            />
          </div>
        )}
        <div className={styles.contentContainer}>
          <p className={styles.category}>{article_category}</p>
          <div className={classNames(styles.title)}>{title}</div>
          {size === 'b' && <div className={styles.description}>{postContentAfterStrippingContent}</div>}
        </div>
        <div className={styles.bottom}>
          <BookmarkBlogButton blogId={slug} classList={{ root: styles.like, bookmarked: styles.liked }} />
          {!!reading_time && (
            <div className={styles.timeToRead}>
              <Clock />
              {reading_time} minutes
            </div>
          )}
        </div>
      </article>
    </Link>
  );
};

export default ArticleCard;
